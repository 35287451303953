import React from 'react';
import { Link } from 'gatsby';
import Layout from '../layout';
import './404.scss';

const NotFoundPage = () => (
  <Layout title="Not found" className="not-found">
    <div className="not-found-title">
      <h1 className="title">404</h1>
    </div>
    <div className="not-found-content">
      <h2>You found an empty space, just for yourself.</h2>
      <div className="">There are only shadows here, return home!</div>
    </div>
    <div className="not-found-actions">
      <Link to="/" className="btn btn-primary">
        Return home
      </Link>
    </div>
  </Layout>
);

export default NotFoundPage;
